import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { Helmet } from 'react-helmet-async'


function AppLayout() {
    return (
        <>
            <Helmet>
                <title>Best social casino games in Canada</title>
                <meta name="description" content="Best social casino games in Canada" />
            </Helmet>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default AppLayout
